<template>
 <div :class="windowClass">
   <v-container>
        <v-toolbar dense>
          <v-icon left>mdi-chart-tree</v-icon> Top Players 
          
        </v-toolbar>

        <v-row>
            <v-col cols="12">
                 <table class="va-table md-font mt-5" id="over-table">
                    <tr>
                      <td  class="border_bot">BETTOR</td>
                        <td  class="border_bot text-center"># of FIGHTS</td>
                        <td class="text-right border_bot">BALANCE</td>
                    </tr>
                    <tr v-for="(item, index) in bets" :key="index">
                       <td  class="border_bot">{{item.bettor_name}}</td>
                         <td  class="border_bot text-center">{{item.no_fights}}</td>
                          <td  class="border_bot text-right">{{$money(item.balance)}}</td>
                    </tr>
                </table>
            </v-col>
        </v-row>
   </v-container>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      bets:[],
      areas:[],
    from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
      
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.from_date = this.$moment().format("YYYY-MM-DD")
          this.to_date = this.$moment().format("YYYY-MM-DD")
          this.getBets()
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       valStatus(result,status) {
        if(result) {
            if(status == "Submitted") {
              return "Posted"
            } else {
              return status
            }
        } else {
           return "Ongoing fight"
        }
      },
       getBets(){
         this.bets = []
          this.$http.post("report/top_bettors",{event_id: 18}).then(response => {
                    response.data.items != null?this.bets = response.data.items:this.bets=[]
                }).catch(e => {
                 console.log(e.data)
             })
      },
       
    }
  }
</script>